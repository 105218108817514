<template>
  <div class="container-fluid">
    <header style="position: relative;">
      <div class="title">
        <h1>Sebastiano Minotti Web Services</h1>
        <span style="position: absolute; bottom: 2px; left: 10px;">Sviluppo siti web, web design | Graphic design</span>
      </div>
      <div class="menuDesktop">
        <button v-for="button in arrayBottoni" :key="button.page">{{ button.text }}</button>
      </div>
      <div class="menuMobile">
        <i @click="toggleMenu" :class="iconaHamburger"></i>
      </div>
      <div :class="state" class="navMobile">
        <button v-for="button in arrayBottoni" :key="button.page">{{ button.text }}</button>
      </div>
    </header>
    <main>
      <div class="container-fluid">
        <div class="row">
          <div class="box col-12 col-md-8">
            <div class="container-fluid">
              <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img :src="astronauta" class="d-block w-100" alt="...">
    </div>
    <div class="carousel-item">
      <img :src="immagine2" class="d-block w-100" alt="...">
    </div>
    <div class="carousel-item">
      <img :src="sfondo4" class="d-block w-100" alt="...">
    </div>
    <div class="carousel-item">
      <img :src="sfondo" class="d-block w-100" alt="...">
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
       
            </div>
          </div>
          <div class="box col-12 col-md-4">ciao</div>
          <div class="striscia col-12 col-md-12"><h2>Sviluppo siti Web | Web Design | Graphic Design</h2></div>
          <div class="box col-12 col-md-4">ciao</div>
          <div class="box col-12 col-md-4">ciao</div>
          <div class="box col-12 col-md-4">ciao</div>
        </div>
      </div>
    </main>
    <footer></footer>
  </div>
</template>

<script>
import astronauta from '@/assets/immagini/astronauta.jpg';
import immagine2 from '@/assets/immagini/e1c9b3cf6ee3eb04a64a94c7abed549f.jpg';
import sfondo from '@/assets/immagini/sfondo.jpg';
import sfondo4 from '@/assets/immagini/wp7242558.jpg'

export default {
  name: 'App',
  data() {
    return {
      iconaHamburger: 'fa-solid fa-bars-staggered',
      state: 'closed',
      astronauta,
      immagine2,
      sfondo,
      sfondo4,
      arrayBottoni: [
        { text: 'Home', page: 'Home' },
        { text: 'About', page: 'About' },
        { text: 'Contatti', page: 'Contatti' },
        { text: 'Servizi', page: 'Servizi' }
      ],
    };
  },
  methods: {
    toggleMenu() {
      if (this.state === 'closed' && this.iconaHamburger === 'fa-solid fa-bars-staggered') {
        this.state = 'open';
        this.iconaHamburger = 'fa-brands fa-xing';
      } else {
        this.iconaHamburger = 'fa-solid fa-bars-staggered';
        this.state = 'closed';
      }
    },
  },
}
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.container-fluid {
  width: 100%;
  height: 100vh;
}

header, footer {
  width: 100%;
  height: 90px;
  background-color: rgb(48, 82, 205);
}

header {
  display: flex;
  justify-content: space-between;
}

.title {
  display: flex;
  align-items: center;
}

.title h1 {
  color: rgb(255, 255, 255);
  margin: 10px;
  font-size: 2rem;
}

header span {
  color: rgb(196, 244, 255);
  font-size: 1em;
}

.menuDesktop {
  width: auto;
  min-width: 500px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.menuDesktop button {
  width: 120px;
  height: 40px;
  border: none;
  outline: none;
  font-size: 1rem;
  background-color: rgb(48, 82, 205);
  color: white;
  margin: 10px;
  transition: 1200ms;
}

.menuDesktop button:hover {
  border: none;
  color: rgb(255, 0, 255);
  transition: 1200ms;
  transform: scale(1.1);
  background-color: rgb(196, 244, 255);
}

.closed {
  opacity: 0;
  transition: 900ms;
}

.open {
  opacity: 1;
  transition: 900ms;
}

.menuMobile {
  display: none;
}

.navMobile {
  position: absolute;
  display: none;
  width: 180px;
  z-index: 999;
  height: 260px;
  background-color: rgba(0, 0, 0, 0.6);
  top: 80px;
  right: 15px;
}

main {
  width: 100%;
  height: auto;
  min-height: calc(100vh - 180px);
  overflow-x: hidden;
}

.box {
  height: 350px;
  background-color: blue;
  border: 5px solid rgb(255, 255, 255);
}

.box:nth-of-type(1) {
  height: 550px;
}

.containerCarosello {
  position: relative;
  width: 100%;
  height: 500px;
  min-height: 500px;
  margin-top: 20px;
}

.striscia {
  height: 80px;
  display: flex;
  align-items: center;
}

.striscia h2 {
  color: rgb(16, 71, 159);
  animation: slide 15s linear infinite;
  z-index: 0;
}

@keyframes slide {
  0% {
    transform: translateX(-110%);
  }
  100% {
    transform: translate(210%);
  }
}

/* MEDIA QUERY */
@media screen and (max-width: 460px) {
  .title h1 {
    font-size: 1rem;
  }

  .menuDesktop {
    display: none;
  }

  .menuMobile {
    display: block;
    width: 50px;
    height: 50px;
    background-color: white;
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  header span {
    font-size: 0.7em;
    color: rgb(196, 244, 255);
    bottom: 18px !important;
  }

  .menuMobile i {
    font-size: 1.4rem;
    color: rgb(48, 82, 205);
  }

  .navMobile {
    display: block;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .navMobile button {
    width: 120px;
    height: 40px;
    border: none;
    outline: none;
    margin: 10px;
    border-radius: 4px;
    background-color: rgb(48, 82, 205);
    color: white;
  }

  .striscia {
    height: 30px;
  }

  .striscia h2 {
    font-size: 0.9rem;
  }
}

@media screen and (min-width: 460px) {
  .navMobile, .menuMobile {
    display: none;
  }
}
</style>
